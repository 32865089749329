.container {
  background: var(--color-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.todoRow {
  display: grid;
  --horizontal-padding: 18px;
  grid-template-columns: var(--horizontal-padding) 20px auto 20px var(--horizontal-padding);
  column-gap: 8px;
  align-items: center;
}
.alwaysVisibleContent {
  grid-template-rows: 76px;
}
.checkbox {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-self: center;
}
.checkboxLoading {
  background: var(--color-background);
}
.caret {
  --rotate: 1;
  transform: rotate(calc(var(--rotate) * 90deg));
  transition: transform 0.2s ease-in-out;
}
.caretExpanded {
  --rotate: -1;
}
.title {
  grid-column: 3 / 4;
}
.expandButton {
  grid-column: 4 / 5;
  align-self: stretch;
  display: flex;
  place-content: center;
  /* Increase button hit area */
  margin-inline: calc(-1 * var(--horizontal-padding));
  padding-inline: var(--horizontal-padding);
}
.expandedSection {
  grid-template-rows: auto auto;
}
.description {
  grid-column: 3 / 4;
}
.description p {
  /* Prevent text from starting too far down */
  margin-top: 0;
}
.buttons {
  grid-column: 1 / 6;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  gap: 1px;
}
.hidden {
  display: none;
}

.description, .title {
  word-break: break-word;
}
