import { useState } from 'react';
import { Pressable, View } from 'react-native';

import { ActivityIndicator, Checkbox, MaterialIcon, Text } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { default as cssStyles, unstable_styles as cssUnstableStyles } from './TodoItem.module.css';
import { TodoItemButton } from './TodoItemButton';

import type { TodoItemButtonProps } from './TodoItemButton';
import type { TodoDetailOut } from '@almond/api-types';

export { LoadingTodoItem } from './LoadingTodoItem';

export type TodoCtaButton = Pick<TodoItemButtonProps, 'icon'> & {
  isVisible?: (item: TodoDetailOut) => boolean;
  onPress: (item: TodoDetailOut) => void;
  label: TodoItemButtonProps['label'] | ((item: TodoDetailOut) => TodoItemButtonProps['label']);
};

type TodoItemProps = {
  item: TodoDetailOut;
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  onComplete?: (isCompleted: boolean) => Promise<void>;
  ctaButtons?: TodoCtaButton[];
};

export const TodoItem = (props: TodoItemProps) => {
  const { item, onComplete, isExpanded, onExpand, ctaButtons } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (newVal: boolean) => {
    if (!onComplete) {
      return;
    }

    try {
      setIsLoading(true);
      await onComplete(newVal);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View
      style={cssUnstableStyles.container}
      role="region"
      // dataSet isn't in react-native type definitions, but it's supported in react-native-web.
      // https://github.com/necolas/react-native-web/releases/tag/0.13.0
      // @ts-expect-error
      dataSet={{ testclass: 'TodoItem', uuid: item.uuid }}
    >
      <View style={combineUnstableStyles(cssUnstableStyles.alwaysVisibleContent, cssUnstableStyles.todoRow)}>
        <Checkbox
          style={cssUnstableStyles.checkbox}
          checked={!!item.completedAt}
          onChange={onChange}
          isDisabled={isLoading || !props.onComplete}
          aria-label={item.completedAt ? 'Mark as not complete' : 'Mark as complete'}
        />
        {isLoading && (
          <ActivityIndicator
            style={combineUnstableStyles(cssUnstableStyles.checkbox, cssUnstableStyles.checkboxLoading)}
            size={20}
            lineWidth={3}
          />
        )}
        <Text style={cssUnstableStyles.title} fontStyle="bold" size="m">
          {item.title}
        </Text>
        <Pressable
          style={cssUnstableStyles.expandButton}
          onPress={() => onExpand(!isExpanded)}
          role="button"
          aria-expanded={isExpanded}
          aria-controls={`${item.uuid}-details`}
          aria-label={`${isExpanded ? 'Hide' : 'Show'} details`}
          nativeID={`${item.uuid}-label`}
        >
          <MaterialIcon
            source="chevron-right"
            style={combineUnstableStyles(cssUnstableStyles.caret, isExpanded && cssUnstableStyles.caretExpanded)}
            aria-hidden
          />
        </Pressable>
      </View>
      <section
        className={[cssStyles.todoRow, cssStyles.expandedSection, isExpanded ? '' : cssStyles.hidden].join(' ')}
        id={`${item.uuid}-details`}
        role={isExpanded ? 'region' : undefined}
        aria-labelledby={`${item.uuid}-label`}
      >
        <Text style={cssUnstableStyles.description}>
          {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
          <div dangerouslySetInnerHTML={{ __html: item.htmlDescription }} />
        </Text>

        {ctaButtons?.length ? (
          <View style={cssUnstableStyles.buttons}>
            {ctaButtons.map(({ isVisible, onPress, label, ...ctaButton }, index) => {
              if (isVisible && !isVisible(item)) {
                return null;
              }

              return (
                <TodoItemButton
                  key={index}
                  onPress={() => onPress(item)}
                  label={typeof label === 'function' ? label(item) : label}
                  {...ctaButton}
                />
              );
            })}
          </View>
        ) : null}
      </section>
    </View>
  );
};
