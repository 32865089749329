import { useMemo, useState } from 'react';
import { Pressable, View } from 'react-native';

import { Accordion, MaterialIcon, Text, useTheme } from '@almond/ui';
import groupBy from 'object.groupby';

import { logErrorToSentry } from '~/modules/logging';

import { CATEGORIES } from '../../config';
import { TodoItem } from '../TodoItem';

import { themedStyles } from './styles';

import type { TodoCtaButton } from '../TodoItem';
import type { TodoCategoryEnum, TodoDetailOut } from '@almond/api-types';

export { LoadingTodoList } from './LoadingTodoList';

type TodoListProps = {
  todos: readonly TodoDetailOut[];
  archivedTodos: readonly TodoDetailOut[];
  showAllCategories?: boolean;
  onClickAddTodo?: (category: (typeof CATEGORIES)[number]['id']) => void;
  onComplete: (item: TodoDetailOut, isCompleted: boolean) => Promise<void>;
  ctaButtons?: TodoCtaButton[];
};

export const TodoList = (props: TodoListProps) => {
  const { todos, archivedTodos, showAllCategories, onClickAddTodo, onComplete, ctaButtons } = props;
  const [styles] = useTheme(themedStyles);
  const [expandedItem, setExpandedItem] = useState<TodoDetailOut | null>(null);

  const groupedTodos = useMemo<{ [key in TodoCategoryEnum]?: TodoDetailOut[] }>(() => {
    const unknownCategory = todos.find(t => !CATEGORIES.find(c => c.id === t.category));

    if (unknownCategory) {
      // Ignore the unknown category, but log to Sentry so we know of the issue
      logErrorToSentry('A todo was returned with an unknown category', {
        todo_uuid: unknownCategory.uuid,
        category: unknownCategory.category,
      });
    }

    return groupBy(todos, todo => todo.category);
  }, [todos]);

  return (
    <View testID="TodoList" style={styles.content}>
      {CATEGORIES.map(category => {
        const categoryTodos = groupedTodos[category.id] ?? [];

        if (!categoryTodos.length && !showAllCategories) {
          return null;
        }

        return (
          <View
            key={category.id}
            style={[styles.content, styles.category]}
            // @ts-ignore
            dataSet={{ category: category.id }}
          >
            <View style={[styles.categoryTitle, categoryTodos.length ? styles.categoryTitleWithItems : null]}>
              <Text fontStyle="bold" size="l">
                {category.title}
              </Text>
              {onClickAddTodo && (
                <Pressable
                  role="button"
                  onPress={() => onClickAddTodo(category.id)}
                  aria-label={`Create new ${category.title} To Do`}
                  style={styles.circleButton}
                >
                  <MaterialIcon source="add" size={20} color="lightSecondaryBackground" />
                </Pressable>
              )}
            </View>
            <View style={styles.items}>
              {categoryTodos.map(todo => {
                return (
                  <TodoItem
                    item={todo}
                    key={todo.uuid}
                    isExpanded={expandedItem?.uuid === todo.uuid}
                    onExpand={isExpanded => {
                      if (isExpanded) {
                        setExpandedItem(todo);
                      } else {
                        setExpandedItem(null);
                      }
                    }}
                    onComplete={isCompleted => onComplete(todo, isCompleted)}
                    ctaButtons={ctaButtons}
                  />
                );
              })}
            </View>
          </View>
        );
      })}
      {!!archivedTodos.length && (
        <Accordion
          style={styles.category}
          title={
            <View style={[styles.categoryTitle, archivedTodos.length ? styles.categoryTitleWithItems : null]}>
              <Text fontStyle="bold" size="l">
                Archive
              </Text>
            </View>
          }
        >
          <View
            style={styles.items}
            // @ts-ignore
            dataSet={{ category: 'archived' }}
          >
            {archivedTodos.map(todo => {
              return (
                <TodoItem
                  item={todo}
                  key={todo.uuid}
                  isExpanded={expandedItem?.uuid === todo.uuid}
                  onExpand={isExpanded => {
                    if (isExpanded) {
                      setExpandedItem(todo);
                    } else {
                      setExpandedItem(null);
                    }
                  }}
                  onComplete={isCompleted => onComplete(todo, isCompleted)}
                  ctaButtons={ctaButtons}
                />
              );
            })}
          </View>
        </Accordion>
      )}
    </View>
  );
};
