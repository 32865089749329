import { z } from 'zod';

import { CATEGORIES } from '../../config';

export const TITLE_MAX_LENGTH = 95;

export const validationSchema = z.object({
  category: z.object({
    value: z.object({
      value: z.enum(
        // Some Typescript fiddling to get this to work with z.enum()
        CATEGORIES.map(c => c.id) as [(typeof CATEGORIES)[number]['id'], ...(typeof CATEGORIES)[number]['id'][]]
      ),
      label: z.string(),
    }),
  }),
  title: z
    .string()
    .max(TITLE_MAX_LENGTH, { message: 'To Do is too long. You can put more information in the description.' }),
  description: z.string(),
});
