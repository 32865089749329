import { useCallback, useState } from 'react';

import { ScrollView, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { getChannelPatient } from '~/modules/messaging';
import { Error as ErrorUi } from '~/modules/ui';

import { useGetTodos, useMarkTodos } from '../../hooks';
import { TodoDeleteConfirmation } from '../TodoDeleteConfirmation';
import { TodoFormModal, type TodoFormModalModel } from '../TodoFormModal';
import { LoadingTodoList, TodoList } from '../TodoList';

import { themedStyles } from './styles';

import type { TodoDetailOut } from '@almond/api-types';

export const AdminTodoPage = () => {
  const [styles] = useTheme(themedStyles);
  const { channel } = useChatContext();
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;
  const { active, archived, error, isLoading, modifyCache } = useGetTodos({ patientUuid });
  const [formModel, setFormModel] = useState<TodoFormModalModel | null>(null);
  const [deleteModal, setDeleteModal] = useState<TodoDetailOut | null>(null);
  const { markItem: markItemComplete } = useMarkTodos(patientUuid, 'complete');
  const { markItem: markItemArchive } = useMarkTodos(patientUuid, 'archive');

  const onFormSubmitted = useCallback(
    (newTodo?: TodoDetailOut) => {
      setFormModel(null);

      if (!newTodo) {
        return;
      }

      modifyCache(formModel?.uuid ? 'edit' : 'create', newTodo);
      setFormModel(null);
    },
    [formModel?.uuid, modifyCache]
  );

  const onDeleteConfirm = useCallback(
    (todoToDelete?: TodoDetailOut) => {
      setDeleteModal(null);

      if (!todoToDelete) {
        return;
      }

      modifyCache('delete', todoToDelete);
    },
    [modifyCache]
  );

  if (error) {
    return (
      <ErrorUi
        error={
          new Error(
            // eslint-disable-next-line max-len
            `There was an error fetching the to do list. Let us know in #bugs`,
            { cause: error }
          )
        }
      />
    );
  }

  let content: React.JSX.Element;

  if (isLoading) {
    content = <LoadingTodoList />;
  } else {
    content = (
      <>
        <TodoList
          todos={active}
          archivedTodos={archived}
          showAllCategories
          onClickAddTodo={category => {
            setFormModel({ category });
          }}
          onComplete={async (item, isCompleted) => {
            const result = await markItemComplete(item, isCompleted);

            if (result) {
              modifyCache('archiveStatus', result);
            }
          }}
          ctaButtons={[
            {
              onPress: async todo => {
                const result = await markItemArchive(todo, !todo.archivedAt);

                if (result) {
                  modifyCache('archiveStatus', result);
                }
              },
              label: todo => (todo.archivedAt ? 'Unarchive' : 'Archive'),
            },
            {
              onPress: todo => setFormModel(todo),
              label: 'Edit',
            },
            {
              onPress: todo => setDeleteModal(todo),
              label: 'Delete',
            },
          ]}
        />
        <TodoFormModal
          isVisible={!!formModel}
          patientUuid={patientUuid}
          onRequestClose={onFormSubmitted}
          model={formModel}
        />
        <TodoDeleteConfirmation
          isVisible={!!deleteModal}
          patientUuid={patientUuid}
          onRequestClose={onDeleteConfirm}
          item={deleteModal}
        />
      </>
    );
  }

  return (
    <ScrollView
      containerStyle={styles.background}
      style={styles.scrollContainer}
      contentContainerStyle={styles.wrapper}
    >
      {content}
    </ScrollView>
  );
};
