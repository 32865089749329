import { useEffect, useMemo } from 'react';
import { View } from 'react-native';

import {
  ConnectedCharacterCount,
  ConnectedSelectInput,
  ConnectedTextInput,
  Form,
  SubmitButton,
  Text,
  useBrowserTypeMap,
  useTheme,
} from '@almond/ui';
import { useEvent } from '@almond/utils';
import { zodResolver } from '@hookform/resolvers/zod';

import { Modal } from '~/modules/ui';

import { CATEGORIES } from '../../config';
import { useTodoUpsert } from './useTodoUpsert';
import { TITLE_MAX_LENGTH, validationSchema } from './validations';

import { themedStyles } from './styles';

import type { TodoDetailOut } from '@almond/api-types';
import type { ModalProps } from '~/modules/ui';
import type { z } from 'zod';

export type TodoFormModalModel = Partial<TodoDetailOut>;

const CATEGORY_OPTIONS = CATEGORIES.map(({ id, title }) => ({ value: id, label: title }));

type TodoFormModalProps = Pick<ModalProps, 'isVisible'> & {
  model: TodoFormModalModel | null;
  patientUuid: string | undefined;
  onRequestClose: (newTodo?: TodoDetailOut) => void;
};

export const TodoFormModal = (props: TodoFormModalProps) => {
  const { isVisible, onRequestClose, model, patientUuid } = props;
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();

  const isEdit = !!model?.uuid;

  const formModel = useMemo(
    () => ({
      ...model,
      category: { value: CATEGORY_OPTIONS.find(c => c.value === model?.category) },
    }),
    [model]
  );

  const { error, isMutating, reset, triggerInsert, triggerUpdate } = useTodoUpsert(patientUuid);

  useEffect(() => {
    if (isVisible) {
      reset();
    }
  }, [isVisible, reset]);

  const onSubmit = useEvent(async (values: z.infer<typeof validationSchema>) => {
    try {
      let data: TodoDetailOut;

      if (isEdit) {
        data = await triggerUpdate({
          todo_uuid: model.uuid,
          category: values.category.value.value,
          title: values.title,
          description: values.description,
        });
      } else {
        data = await triggerInsert({
          category: values.category.value.value,
          title: values.title,
          description: values.description,
        });
      }

      onRequestClose(data);
    } catch (e) {
      // Errors will be surfaced by the error value returned from useALmondApiMutation.
      // Catching here so the uncaught error doesn't propagate up to the top error handler
    }
  });

  return (
    <Modal
      isVisible={isVisible}
      title={`${isEdit ? 'Edit' : 'New'} To Do Item`}
      onRequestClose={() => onRequestClose()}
      showClose
      containerStyle={styles.modalContainer}
      contentContainerStyle={isDesktop && styles.modalContent}
    >
      <Form
        name={`${isEdit ? 'Edit' : 'New'} To Do Item`}
        defaultValues={formModel || undefined}
        resolver={zodResolver(validationSchema)}
        onSubmit={onSubmit}
        isDisabled={isMutating}
        isLoading={isMutating}
      >
        <View testID="TodoFormModal">
          <ConnectedSelectInput name="category" options={CATEGORY_OPTIONS} testID="Category" />
          <ConnectedTextInput name="title" placeholder="What is the name of the to do?" accessibilityLabel="Title" />
          <ConnectedCharacterCount name="title">
            {count => {
              return (
                <Text size="m" style={[styles.count, count > TITLE_MAX_LENGTH && styles.countInvalid]}>
                  {count}/{TITLE_MAX_LENGTH} characters
                </Text>
              );
            }}
          </ConnectedCharacterCount>
          <ConnectedTextInput
            name="description"
            placeholder="Any other info to add? Resources, links, ideas for how to accomplish?"
            multiline
            style={styles.description}
            accessibilityLabel="Description"
          />
          <View style={styles.footer}>
            {error && (
              <Text style={styles.error} size="m">
                There was an error saving the To Do item. Please try again.
              </Text>
            )}
            <SubmitButton type="accent" style={styles.submit}>
              {isEdit ? 'Save Changes' : 'Create new To Do item'}
            </SubmitButton>
          </View>
        </View>
      </Form>
    </Modal>
  );
};
