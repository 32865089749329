import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  background: {
    backgroundColor: theme.lightSecondaryBackground,
    flex: 1,
  },
  scrollContainer: {
    scrollbarGutter: 'stable both-edges',
    scrollbarWidth: 5,
  } as any,
  loading: {
    flexGrow: 0,
  },
  wrapper: {
    paddingVertical: 40,
    paddingHorizontal: sizes.SPACING_M,
    marginBottom: sizes.SPACING_XXL,
    maxWidth: 440 + 2 * sizes.SPACING_M,
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
  subtitle: {
    marginTop: 8,
    marginBottom: 30,
  },
  content: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 300,
  },
  noItemsText: {
    marginTop: 24,
    backgroundColor: theme.darkSecondaryBackground,
    height: 38,
    lineHeight: 38,
    borderRadius: 38 / 2,
    alignSelf: 'center',
    paddingHorizontal: 30,
  },
  messageCtaWrapper: {
    marginTop: 20,
    alignItems: 'center',
    gap: 10,
  },
  messageCta: {
    height: 40,
    width: 'min-content' as any,
    paddingHorizontal: 30,
  },
}));
