import React from 'react';
import { View } from 'react-native';

import { Button, ScrollView, Text, useTheme } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';
import { Link, Redirect } from 'expo-router';
import { useGate } from 'statsig-react';

import { ErrorContainer } from '~/modules/logging';
import { LoadingTodoList, TodoList, useGetTodos, useMarkTodos } from '~/modules/todos';
import { Error as ErrorUi, LocalImage } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { unstable_styles as cssStyles } from './todos.module.css';

import { themedStyles } from './styles';

export { ErrorContainer as ErrorBoundary };

const Todos = () => {
  const { isLoading: isGateLoading, value: isFeatureEnabled } = useGate('todos');
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const { active, archived, error, isLoading, modifyCache } = useGetTodos({ patientUuid: currentUser?.patientUuid });
  const { markItem: markItemComplete } = useMarkTodos(currentUser?.patientUuid, 'complete');
  const { markItem: markItemArchive } = useMarkTodos(currentUser?.patientUuid, 'archive');

  if (!isGateLoading && !isFeatureEnabled) {
    return <Redirect href="/(patient)/" />;
  }

  if (error) {
    return (
      <ErrorUi
        error={
          new Error(
            // eslint-disable-next-line max-len
            `There was an error fetching your to do list. Please refresh the page, and if that doesn't work send us a message.`,
            { cause: error }
          )
        }
      />
    );
  }

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <LoadingTodoList />;
  } else if (active.length || archived.length) {
    content = (
      <TodoList
        todos={active}
        archivedTodos={archived}
        onComplete={async (item, isCompleted) => {
          const result = await markItemComplete(item, isCompleted);

          if (result) {
            modifyCache('archiveStatus', result);
          }
        }}
        ctaButtons={[
          {
            isVisible: todo => !todo.completedAt,
            onPress: async todo => {
              const result = await markItemArchive(todo, !todo.archivedAt);

              if (result) {
                modifyCache('archiveStatus', result);
              }
            },
            icon: 'archive',
            label: todo => (todo.archivedAt ? 'Unarchive Item' : 'Archive Item'),
          },
          {
            onPress: async () => {
              window.open('/messages', '_blank');
            },
            icon: 'message',
            label: 'Ask a question',
          },
        ]}
      />
    );
  }

  return (
    <ScrollView
      containerStyle={styles.background}
      style={styles.scrollContainer}
      contentContainerStyle={styles.wrapper}
    >
      <Text size="xxxxl" accessibilityLevel={1} style={cssStyles.center}>
        To Do List
      </Text>
      <Text size="m" style={[styles.subtitle, combineUnstableStyles(cssStyles.center, cssStyles.textBalance)]}>
        Check items off your To Do List to help your Care Team know when and how to follow up
      </Text>
      {!isLoading && !active.length && (
        <View style={styles.content}>
          <LocalImage resizeMode="contain" source="universe1" style={styles.image} />
          <Text style={styles.noItemsText} fontStyle="bold">
            {archived.length ? 'You have completed all of your to dos for now!' : 'No To Dos for now!'}
          </Text>
        </View>
      )}
      {content}
      <View style={styles.messageCtaWrapper}>
        <Text size="m">Did we forget something? Let us know!</Text>
        <Link href="/(patient)/messages" asChild>
          <Button type="secondary" style={styles.messageCta} fixedWidth>
            Message Care Team
          </Button>
        </Link>
      </View>
    </ScrollView>
  );
};

export default Todos;
