import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  background: {
    flex: 1,
    backgroundColor: theme.lightSecondaryBackground,
  },
  scrollContainer: {
    scrollbarGutter: 'stable both-edges',
  } as any,
  loading: {
    flexGrow: 0,
  },
  wrapper: {
    paddingVertical: 12,
    paddingHorizontal: sizes.SPACING_L,
    marginBottom: sizes.SPACING_XXL,
    maxWidth: 440,
    width: '100%',
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
  subtitle: {
    marginTop: 8,
    marginBottom: 30,
  },
  content: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
  },
}));
