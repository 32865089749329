import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  wrapper: {
    // Order reversed so clicking an image brings up the image
    // viewer overlay on top of the sidebar.
    // If we aren't using the default Stream image view overlay,
    // we can switch this to "row" and swap the order of the
    // chat window and sidebar in the DOM
    flexDirection: 'row-reverse',
    flexGrow: 1,
    maxWidth: '100%',
  },
  channelContainer: {
    flexGrow: 1,
    maxWidth: '100%',
  },
  channelContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: '100%',
  },

  loading: {
    backgroundColor: theme.background,
  },
  hide: {
    display: 'none',
  },
  notFoundText: {
    textAlign: 'center',
  },

  joinButton: {
    marginBottom: sizes.SPACING_S,
    marginHorizontal: sizes.SPACING_S,
  },
}));
