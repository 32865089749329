import { useEvent } from '@almond/utils';

import { useAlmondApiMutation } from '~/modules/api';

export const useTodoUpsert = (patientUuid: string | undefined) => {
  const insert = useAlmondApiMutation(
    'post',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/` : null
  );
  const update = useAlmondApiMutation(
    'put',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/{todo_uuid}/` : null
  );

  return {
    error: insert.error || update.data,
    isMutating: insert.isMutating || update.isMutating,
    reset: useEvent(() => {
      insert.reset();
      update.reset();
    }),
    triggerInsert: insert.trigger,
    triggerUpdate: update.trigger,
  };
};
